<template>
  <div>
    <!-- Modal del formulario -->
    <div :class="{'modal': true, 'is-active': !showResult}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" style="max-width: 450px; margin: auto;">

          <div class="field">
            <label class="label">Comprobante de pago</label>
            <div class="file has-name is-right is-medium">
              <label class="file-label" style="width: 100%;">
                <input class="file-input" type="file" name="resume" @change="handleFileUpload" />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">
                    <span class="material-icons">upload</span>
                  </span>
                </span>
                <span class="file-name" style="width: 100%;">{{ file ? file.name : 'No file selected' }}</span>
              </label>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <button class="button is-danger is-outlined is-fullwidth is-rounded is-medium" @click="close">Cerrar</button>
            </div>
            <div class="column">
              <button class="button is-danger is-fullwidth is-rounded is-medium" :class="{ 'is-loading': loadingStatus }" @click="submit">Aceptar</button>
            </div>
          </div>
          <div v-if="error" class="notification is-danger">
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>

    <!-- Modal del resultado -->
    <div :class="{'modal': true, 'is-active': showResult}">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box" style="max-width: 350px; margin: auto;">
          <div class="field">
            <div class="control">
              <p>{{ apiResult }}</p>
            </div>
          </div>
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button class="button is-danger is-outlined is-rounded is-medium" @click="close">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      file: null,
      loadingStatus: false,
      apiResult: null,
      error: null,
      showResult: false
    }
  },
  methods: {
    handleFileUpload (event) {
      this.file = event.target.files[0]
    },
    async submit () {
      if (!this.file) {
        this.error = 'Por favor, selecciona un archivo.'
        return
      }
      try {
        this.loadingStatus = true
        const formData = new FormData()
        formData.append('file', this.file)

        const response = await this.action(formData)

        this.apiResult = response
        this.error = null
        this.showResult = true
      } catch (err) {
        this.error = 'Error al realizar la operación. Por favor, inténtalo de nuevo.'
        this.apiResult = null
      } finally {
        this.loadingStatus = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
.field {
  margin-bottom: 1rem;
}
.button.is-fullwidth {
  width: 100%;
}
.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.is-danger{
  background-color: #EB5965;
}
</style>
